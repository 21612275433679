riot.mount('*');
(function ($) {
  $(() => {
    if (!feature.touch) {
      $('a[href^="#"], .js-anchor-scroll').on('click', e => {
        e.preventDefault()
        let headerHeight = 0
        let $target = $(e.currentTarget.attributes.href.value)
        console.log($target)
        let targetOffset = $target.offset().top
        $('html,body').animate({
            scrollTop: targetOffset - headerHeight - 10
          },
          300
        )
      })
    }
  })
})(jQuery)

$('.js-news').change(function () {
  var r = $('option:selected').val();
  $('#js-content__ui-2019,#js-content__ui-2018,#js-content__ui-2017,#js-content__ui-2016,#js-content__ui-2015,#js-content__ui-2014,#js-content__ui-2013').css('display', 'none');

  if (r == 'js-content__ui-all') {
    $('#js-content__ui-2019,#js-content__ui-2018,#js-content__ui-2017,#js-content__ui-2016,#js-content__ui-2015,#js-content__ui-2014,#js-content__ui-2013').fadeIn('slow');

  } else {
    $('#' + r).fadeIn('slow');
  }

});

$(function(){

  $('img').on('mousedown mouseup',function(e){
    return false;
  });
  $('img').on('contextmenu',function(e){
    return false;
  });
 
});

var pagetop = $('.js-pagetop');
$(window).scroll(function () {

  if ($(this).scrollTop() > 100 )
  {
    pagetop.fadeIn();
  }else{
    pagetop.fadeOut();
  }
});

$(function () {



  $('body, html').animate({
    scrollTop: 0
  }, 500);
  return false;


});




pagetop.click(function () {

  $('body, html').animate({
    scrollTop: 0
  }, 500);
  return false;
});




$(window).resize(function () {
  var wh = $(window).width();
  if (wh > 750) {

    $('.c-header-nav').css('display', 'block');
    $('.c-header-nav-list').css("display","block");
    $('.c-header-nav-list').css('display', 'inline-flex');
    $('.c-header-nav-list__link[aria-selected] .c-global-nav-text ').css("height", "80px");
    $('.c-header-nav-list__link[aria-selected] .c-global-nav-text ').css("padding-top", "20px");
    $('.c-header-nav-list__link[aria-selected] .c-global-nav-text ').css("border-bottom", "4px solid #ffffff");
    $('.c-menu-nav--sp_close').css('display', 'none');
    $('.c-menu-nav--sp').css('display', 'block');
    $('.c-header-hydemenu--service').css('display', 'none');
    $('.c-header-hydemenu--corp').css('display', 'none');
    $('.c-header-hydemenu--recruit').css('display', 'none');
    $('.c-header-nav-list__link').css('display', 'inline-flex');
    $('.c-header-nav-list__link').css('align-content', 'stretch');

  } else {
    $('.c-header-nav-list__link[aria-selected] .c-global-nav-text ').css("border-bottom", "none");
    $('.c-header-nav-list').css('display', 'none');
    $('.js-sp-link').css('pointer-events', 'auto');
    $('.c-header-nav-list').css('top', '60px');

  }
})

$(".js-sp_menu__btn").on("click", function () {

  var wh = $(window).width();

  if (wh < 750) {
    $('.c-header-nav-list__link[aria-selected] .c-global-nav-text ').css("padding-top", "10px");
    $('.c-header-nav-list').slideToggle(true);
    //$('.c-header-nav-list').css("display","block");
    $('.js-sp-link').css('pointer-events', 'none');
    //$('.c-menu-nav--sp').css('display','none');
    $('.c-header-nav').css('display', 'block');
    $('.c-menu-nav--sp_close').fadeIn(1000);
    $('.c-header-nav-list').css('z-index', '200');
    $('.c-header-nav-list').css('top', '60px');
    $('.c-header-hydemenu--service').css('display', 'none');
    $('.c-header-hydemenu--corp').css('display', 'none');
    $('.c-header-hydemenu--recruit').css('display', 'none');

  } else {
    $('.c-header-nav-list').css('display', 'inline-flex');

  }
});




$(".js-sp_hyde_menu_service").on("click", function () {
  var wh = $(window).width();
  if (wh < 750) {

    $('.js-sp-link').css('pointer-events', 'none');
    $('.c-header-hydemenu__link--service').css('pointer-events', 'none');
    $('.c-header-hydemenu--service').slideToggle(true); //.slideToggle( true );
    $('.c-header-hydemenu--corp').toggle(false);
    $('.c-header-hydemenu--recruit').toggle(false);
    $('.c-header-nav-list').css("display", "block");
    $('.c-header-hydemenu__link--service').css('pointer-events', 'auto');
  }
});
//touchstart
$(".js-sp_hyde_menu_corpo").on("click", function () {
  var wh = $(window).width();
  if (wh < 750) {

    $('.js-sp-link').css('pointer-events', 'none');
    $('.c-header-hydemenu__link--corpo').css('pointer-events', 'none');
    $('.c-header-hydemenu--corp').slideToggle(true);
    $('.c-header-hydemenu--service').toggle(false);
    $('.c-header-hydemenu--recruit').toggle(false);
    $('.c-header-nav-list').css("display", "block");
    $('.c-header-hydemenu__link--corpo').css('pointer-events', 'auto');
  }
});

$(".js-sp_hyde_menu_recruit").on("click", function () {
  var wh = $(window).width();
  if (wh < 750) {

    $('.js-sp-link').css('pointer-events', 'none');
    $('.c-header-hydemenu__link--recruit').css('pointer-events', 'none');
    $('.c-header-hydemenu--recruit').slideToggle(true);
    $('.c-header-hydemenu--corp').toggle(false);
    $('.c-header-hydemenu--service').toggle(false);
    $('.c-header-nav-list').css("display", "block");
    $('.c-header-hydemenu__link--recruit').css('pointer-events', 'auto');
  }
});

$(function () {

  // スマホ判定
  var _ua = (function (u) {
    return {
      Tablet: (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
        u.indexOf('ipad') != -1 ||
        (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
        (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
        u.indexOf('kindle') != -1 ||
        u.indexOf('silk') != -1 ||
        u.indexOf('playbook') != -1,
      Mobile: (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
        u.indexOf('iphone') != -1 ||
        u.indexOf('ipod') != -1 ||
        (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
        (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
        u.indexOf('blackberry') != -1
    }
  })(window.navigator.userAgent.toLowerCase());

  // NOTE: スマホ時のviewport上書き
  if (_ua.Mobile) {

    if (window.screen.width < 375) {
      $('meta[name="viewport"]').attr('content', 'width=375,user-scalable=no');
    } else {
      $('meta[name="viewport"]').attr('content', 'width=device-width,user-scalable=no');
    }
  }
});